<template>
  <div class="wrap" :style="{marginTop: '-' + $store.state.safetyTop + 'px'}">
    <div class="topBox" :style="{ paddingTop: $store.state.safetyTop + 'px' }">
      <div class="inner">
        <div
          class="setBtn"
          @click="
            toOutsideChain('bridge:/baseWorker/openSettingsView/pageInit')
          "
        ></div>
        <div class="info">
          <div class="dt">
            <div class="pic">
              <img src="~@/assets/images/personal/personalVia.jpg" alt="" />
            </div>
            <!-- <div class="grade">平民</div> -->
          </div>
          <div class="dd">
            <div class="row">
              <div class="name">{{ $store.state.userName }}</div>
              <!-- <div v-if="$store.state.userIdNum" class="attestation">
                已认证
              </div> -->
            </div>
            <div class="identityCard">{{ $store.state.userIdNum }}</div>
          </div>
        </div>
      </div>
      <div
        class="signIn"
        @click="
          toOutsideChain('bridge:/baseWorker/openWorkerCoinSignInView/pageInit')
        "
      >
        签到有惊喜
      </div>
    </div>
    <main>
      <div class="district">
        <div class="hTil">生态专区</div>
        <ul>
          <li>
            <div class="icon" 
            @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=myOrder')"
            >
              <img src="~@/assets/images/personal/zoologyIcon1.png" alt="" />
            </div>
            <div class="p">我的订单</div>
          </li>
          <li>
            <div class="icon" @click="expect">
              <img src="~@/assets/images/personal/zoologyIcon2.png" alt="" />
            </div>
            <div class="p">购物车</div>
          </li>
          <li>
            <div class="icon"  @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=collection')">
              <img src="~@/assets/images/personal/zoologyIcon3.png" alt="" />
            </div>
            <div class="p">我的收藏</div>
          </li>
          <li>
            <div class="icon" @click="expect">
              <img src="~@/assets/images/personal/zoologyIcon4.png" alt="" />
            </div>
            <div class="p">浏览历史</div>
          </li>
          <li>
            <div class="icon" @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=address')">
              <img src="~@/assets/images/personal/zoologyIcon5.png" alt="" />
            </div>
            <div class="p">我的地址</div>
          </li>
        </ul>
      </div>
      <div class="district">
        <div class="hTil">我的工作</div>
        <ul>
          <li
            @click="toOutsideChain('bridge:/baseWorker/openAtteView/pageInit')"
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon1.png" alt="" />
            </div>
            <div class="p">考勤查询</div>
          </li>
          <li
            @click="
              toOutsideChain('bridge:/baseWorker/openSalaryCardView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon2.png" alt="" />
            </div>
            <div class="p">工资卡</div>
          </li>
          <li
            @click="
              toOutsideChain('bridge:/baseWorker/openWagesCheckView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon3.png" alt="" />
            </div>
            <div class="p">工资查询</div>
          </li>
          <li
            @click="
              toOutsideChain('bridge:/baseWorker/openMyContractView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon4.png" alt="" />
            </div>
            <div class="p">电子合同</div>
          </li>
          <li
            @click="
              toOutsideChain(
                'bridge:/baseWorker/openEntraceInviteView/pageInit'
              )
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon5.png" alt="" />
            </div>
            <div class="p">入场邀请</div>
          </li>
          <li @click="to('/personalExcellentScore')">
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon6.png" alt="" />
            </div>
            <div class="p">华建优分</div>
          </li>
          <li @click="to('/course')">
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon7.png" alt="" />
            </div>
            <div class="p">我的课程</div>
          </li>
          <li @click="loginJump(1, ifProduction()?'bridge:/webview/open/pageInit?url=https%3A%2F%2Fconst.caihcloud.com%2Fworker-app-h5%2FloanIntroduce%3FpayrollList%3D1':'bridge:/webview/open/pageInit?url=http%3A%2F%2F10.96.200.175%2Fworker-app-h5%2FloanIntroduce%3FpayrollList%3D1')">
          
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon8.png" alt="" />
            </div>
            <div class="p">我的借支</div>
          </li>
         
          <li 
          @click="loginJump(1, 'bridge:/webview/open/pageInit?url=https://huajiantong.com/h5Mall/pages/passport/authlogin?pageName=reportList')"
          >
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon9.png" alt="" />
            </div>
            <div class="p">我的体检</div>
          </li>
          <li @click="expect">
            <div class="icon">
              <img src="~@/assets/images/personal/workIcon10.png" alt="" />
            </div>
            <div class="p">我的保险</div>
          </li>
        </ul>
      </div>
      <div class="district">
        <div class="hTil">我的班组</div>
        <div class="list">
          <div
            class="row"
            @click="
              toOutsideChain('bridge:/baseWorker/openMyOnTeamView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon1.png" alt="" />
            </div>
            <div class="p">班组登记</div>
          </div>
          <div
            class="row"
            @click="
              toOutsideChain('bridge:/baseWorker/openMyLogTeamView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon2.png" alt="" />
            </div>
            <div class="p">班组记工</div>
          </div>
          <div
            class="row"
            @click="
              toOutsideChain('bridge:/baseWorker/openTeamPayrollView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon3.png" alt="" />
            </div>
            <div class="p">班组工资</div>
          </div>
          <div
            class="row"
            @click="
              toOutsideChain('bridge:/baseWorker/openLessonRecordView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon4.png" alt="" />
            </div>
            <div class="p">学习成果</div>
          </div>
          <div class="line"></div>
          <div
            class="row"
            @click="
              toOutsideChain(
                'bridge:/baseWorker/openProjectNoticeView/pageInit'
              )
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon5.png" alt="" />
            </div>
            <div class="p">维权告示牌</div>
          </div>
          <div
            class="row"
            @click="
              toOutsideChain('bridge:/baseWorker/openMyComplaintView/pageInit')
            "
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon6.png" alt="" />
            </div>
            <div class="p">我的投诉</div>
          </div>
          <div
            class="row"
            @click="toOutsideChain('bridge:/base/helpServe/pageInit')"
          >
            <div class="icon">
              <img src="~@/assets/images/personal/groupIcon7.png" alt="" />
            </div>
            <div class="p">帮助与反馈</div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { expect } from '@/api/public';

import { ifProduction } from '@/api/request';

const store = useStore();
const router = useRouter();
const prestoreId = ref(-1);
const prestoreUrl = ref('');

const to = (url) => {
    router.push(url)
}
const toOutsideChain = (url) => {
    if(!url) return false;
    window.location.href = url;
}


const getUserInfo = () => {
  let userId = store.state.userIdNum;
  if(!userId){
    window.location.href = "bridge:/base/getAppUserInfo/pageInit";
  }
}
function callBackUserInfo(info){
    store.commit('setUserInfo', info);
}

// 业务按钮跳转
const loginJump = (num, url) => {
  prestoreId.value = num
  prestoreUrl.value = url
  console.log(prestoreUrl.value)
  window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
}

onMounted(function(){
    // 登录状态
    window.callbackLoginEvent = (isLogin) => {
      // isLogin 1 已登录 0 未登录
      if (isLogin == '1') {
        if(prestoreId.value == 1){
          window.location.href = prestoreUrl.value
        }else if(prestoreId.value == 0){
          router.push(prestoreUrl.value)
        }
        prestoreId.value = -1
        prestoreUrl.value = ""
      }else {
        prestoreId.value = -1
        prestoreUrl.value = ""
        // *****  调起登录页面的协议
        window.location.href = "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
      }
    }
    
    window.callBackUserInfo = callBackUserInfo;
    getUserInfo();
})
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  padding-top: 0;
}
.topBox {
  position: relative;
  height: 4.35rem;
  background: url(~@/assets/images/personal/personalTopBg.jpg) no-repeat
    no-repeat top center;
  background-size: cover;
  .inner {
    position: relative;
  }
  .setBtn {
    position: absolute;
    right: 0.18rem;
    top: 0.12rem;
    width: 0.6rem;
    height: 0.6rem;
    background: url(~@/assets/images/personal/iconSet.png) no-repeat center;
    background-size: 0.36rem;
  }
  .info {
    position: absolute;
    left: 0.3rem;
    top: 1.15rem;
    display: flex;
    .dt {
      position: relative;
      width: 1.3rem;
      height: 1.3rem;
      margin-right: 0.3rem;
      .pic {
        overflow: hidden;
        border: 0.04rem solid #fff;
        border-radius: 50%;
      }
      .grade {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -0.04rem;
        height: 0.34rem;
        line-height: 0.34rem;
        width: 0.84rem;
        font-size: 0.2rem;
        color: #606f8b;
        padding-left: 0.34rem;
        border-radius: 0.34rem;
        background: url(~@/assets/images/personal/iconGrade1.png) no-repeat
            0.13rem center / 0.15rem,
          linear-gradient(to right, #c2d2ee, #fff);
      }
    }
    .dd {
      padding-top: 0.23rem;
      .row {
        display: flex;
        align-items: center;
        .name {
          font-size: 0.36rem;
          line-height: 0.46rem;
          margin-right: 0.16rem;
          color: #fff;
        }
        .attestation {
          width: 1.12rem;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.2rem;
          color: #de9a1b;
          padding-left: 0.44rem;
          background: url(~@/assets/images/personal/iconAttestation.png)
            no-repeat center;
          background-size: contain;
        }
      }
      .identityCard {
        font-size: 0.22rem;
        color: #fff;
        font-family: "DIN-Bold";
      }
    }
  }
  .signIn {
    position: absolute;
    right: 0.3rem;
    bottom: 0.95rem;
    font-size: 0.24rem;
    color: #fff;
    height: 0.58rem;
    line-height: 0.58rem;
    padding: 0 0.16rem 0 0.48rem;
    font-size: 0.24rem;
    border-radius: 0.58rem;
    background: url(~@/assets/images/personal/iconGift.png) no-repeat 0.14rem
        center / 0.28rem,
      linear-gradient(to right, #6ab4fc, #007ef8);
  }
}
main {
  position: relative;
  z-index: 5;
  border-radius: 0.2rem 0.2rem 0 0;
  background: #f3f4f6;
  margin-top: -0.35rem;
  padding-bottom: 0.3rem;
}
.district {
  border-radius: 0.2rem;
  background: #fff;
  padding: 0.3rem;
  margin-bottom: 0.3rem;
  .hTil {
    font-size: 0.26rem;
    color: #3c3f50;
    line-height: 0.36rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  li {
    width: 1.06rem;
    margin-right: 0.4rem;
    margin-top: 0.3rem;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:nth-child(-n + 5) {
      margin-top: 0;
    }
    .icon {
      width: 0.46rem;
      height: 0.46rem;
      margin: 0 auto 0.06rem;
    }
    .p {
      font-size: 0.24rem;
      color: #3c3f50;
      text-align: center;
      line-height: 0.32rem;
    }
  }
  .row {
    display: flex;
    height: 0.8rem;
    align-items: center;
    background: url(~@/assets/images/iconMore.png) no-repeat right center;
    background-size: 0.1rem;
    .icon {
      width: 0.32rem;
      margin-right: 0.28rem;
    }
    .p {
      font-size: 0.24rem;
      color: #3c3f50;
      line-height: 0.4rem;
    }
  }
  .line {
    margin: 0.2rem 0;
    border-bottom: 0.01rem solid #eee;
  }
}
</style>
  
  