import { get, post,setBaseURLGet } from './request';


// 获取推荐剧
function getRecommend(data){
    return get('/app-api/business/yanhua/get-recommend?page=0&size=50', data)
}

// 培训课程
function getPeiXunCenterV2Recommend( data){
    return setBaseURLGet("https://pxsc.yjaqpx.net","/app/PeiXunCenterV2/recommend?page=1&size=50", data)
}



export {
    // getNewsTypes,
    // getNews,
    // getNewsInfo,
    // getRelatedNewsList,
    // updateNum,
    // getEquipmentTypes,
    // getEquipment,
    // getPositionLevel,
    // getProfessionalCategory,
    // addBuildingSub,
    getRecommend,
    getPeiXunCenterV2Recommend
    // addInsuranceSub,
    // addDigitalSub,
    // getPopularKeys
}