<template>
  <div class="Waterfall">
    <div
      class="li"
      v-for="(item, i) in popularList"
      :key="i"
      :class="['type'+item.typeIndex]"
    >
      <div  v-if="item.typeIndex === 0"  @click="toOutsideChain(item.link)">
        <div class="pic">
          <div class="state">正在热播</div>
          <img :src="item.hcoverImg" alt="" />
        </div>
        <div class="txt">
          <h4>{{ item.title }}</h4>
        </div>
      </div>
      <div v-if="item.typeIndex === 1"  @click="toOutsideChain(item.detailUrl)">
        <div class="pic">
          <img :src="item.fengMian" alt="" />
        </div>
        <div class="txt">
          <h4>{{ item.name }}</h4>
          <div class="price">
            <div class="num"><em>价格面议</em></div>
            <div class="label">全网最低</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="li" @click="toOutsideChain('https://yhkj-test.pomoho.com/')">
      <div class="pic">
        <div class="state">正在热播</div>
        <img src="~@/assets/images/home/homeListPic1.jpg" alt="" />
      </div>·
      <div class="txt">
        <h4>我能接到未来电话</h4>
      </div>
    </div>
    <div class="li jr li1">
      <div class="icon">
        <img src="~@/assets/images/home/homeListIcon1.png" alt="" />
      </div>
      <h3>劳务金融贷</h3>
      <h4>单笔借支额度:每个工人单笔贷款额度不超5000(每月一次)</h4>
      <p>
        借款要求：劳务公司质押产值单合同等相关资料,项目有使用一号工班劳务管理系统
      </p>
      <p>
        合作的金融机构：<br />
        鹏金所已服务客户数:5000+
      </p>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeListPic2.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>商品名称商品名称商品名称商品名称商品名称</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li" @click="toOutsideChain('https://yhkj-test.pomoho.com/')">
      <div class="pic">
        <div class="state">正在热播</div>
        <img src="~@/assets/images/home/homeListPic3.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>狂飙之至尊神瞳</h4>
      </div>
    </div>
    <div class="li jr li2">
      <div class="icon">
        <img src="~@/assets/images/home/homeListIcon3.png" alt="" />
      </div>
      <h3>工友体检服务</h3>
      <p>
        文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍
      </p>
      <div class="p">已有<em>98071</em>人享受服务</div>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeListPic4.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>商品名称商品名称商品名称商品名称商品名称</h4>
        <div class="price">
          <div class="num"><em>价格面议</em></div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeListPic5.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>2024年二级建造师864私教班</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div>
    <div class="li jr li3">
      <div class="icon">
        <img src="~@/assets/images/home/homeListIcon4.png" alt="" />
      </div>
      <h3>个人工伤保险</h3>
      <p>
        文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍
      </p>
      <div class="p">已有<em>98071</em>人享受服务</div>
    </div>
    <div class="li jr li4">
      <div class="icon">
        <img src="~@/assets/images/home/homeListIcon2.png" alt="" />
      </div>
      <h3>农民工法律援助</h3>
      <p>
        文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍文案介绍
      </p>
      <div class="p"><em>981</em>位律师为您提供服务</div>
    </div>
    <div class="li">
      <div class="pic">
        <img src="~@/assets/images/home/homeHotPic6.jpg" alt="" />
      </div>
      <div class="txt">
        <h4>商品名称商品名称商品名称商品名称商品名称...</h4>
        <div class="price">
          <div class="num"><em>¥</em>58,981</div>
          <div class="label">全网最低</div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script setup>
import { windowWidth } from "vant/lib/utils";
import { ref, onMounted, nextTick, watch } from "vue";

const props = defineProps({
  listData: {
    type: Array,
    default: [],
  },
});

const popularList = ref([]);
const popularListZ = ref([]);

watch(
  () => props.listData,
  (newVal, oldVal) => {
    popularList.value = [];
    getPopularList(newVal);
  }
);

// 底部刷新
function scrollSx() {
  //滚动条在Y轴上的滚动距离
  function getScrollTop() {
    var scrollTop = 0,
      bodyScrollTop = 0,
      documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop =
      bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  }
  //文档的总高度
  function getScrollHeight() {
    var scrollHeight = 0,
      bodyScrollHeight = 0,
      documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight =
      bodyScrollHeight - documentScrollHeight > 0
        ? bodyScrollHeight
        : documentScrollHeight;
    return scrollHeight;
  }
  //浏览器视口的高度
  function getWindowHeight() {
    var windowHeight = 0;
    if (document.compatMode == "CSS1Compat") {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  }
  let kio = true;

  window.onscroll = function () {
    // console.log(getScrollTop() , getWindowHeight() , getScrollHeight())
    // document.getElementsByClassName("hTil")[1].innerHTML = (getScrollTop() + getWindowHeight() )  +","+ getScrollHeight();
    if (getScrollTop() + getWindowHeight() >= getScrollHeight() - 6) {
      if(kio){
        kio = false
        getPopularList(popularListZ.value);
        setTimeout(()=>{
          kio = true
        },500)
    }
     
    }
  };
}
onMounted(() => {
  
  nextTick(() => {
    scrollSx();
  });
});

function getPopularList(arrs) {
  let arrList = arrs;
  let list = [];
  arrs.forEach((item, i) => {
    let newData = getRandomList([...item], 3, i);
    list.push([...newData.tuList]); // 各取3个
    arrList[i] = newData.list;
  });

  let _popularList = [];
  // console.log("_popularList", list);
  list.forEach((item) => {
    item.forEach((li) => {
      _popularList.push(li);
    });
  });
  // console.log("111",popularList.value)
  popularList.value.push(..._popularList) ;
  popularListZ.value = arrList;
  // console.log("getPopularList3", popularListZ.value);
}

// 随机提取一次
function randomExtraction(arr, typeIndex) {
  let max = arr.length;
  var n = Math.floor(Math.random() * max); // 随机数
  let newArr = arr;
  newArr.forEach((item) => {
    item.typeIndex = typeIndex;
  });

  let tu = newArr.splice(n, 1);
  // tu.typeIndex = typeIndex
  // console.log("name",typeIndex)

  return {
    // n,
    tu, // 提取出来的
    newArr, // 被提取后的数组
  };
}

// 随机提取固定数量
function getRandomList(arr, max, typeIndex) {
  let arrList = arr;
  let list = [];
  // console.log("arr.name", arr);
  for (let i = 0; i <= max - 1; i++) {
    let v = randomExtraction(arrList, typeIndex);
    arrList = v.newArr;
    list.push(...v.tu);
  }
  return {
    list: arrList,
    tuList: list,
  };
}

let columnHeights = [0, 0];

// 跳转外链
const toOutsideChain = (url) => {
  if (!url) return false;
  window.location.href = "bridge:/webview/open/pageInit?url=" + url;
};

let appendElement = (element) => {
  // 计算新元素的高度
  const elementHeight = element.clientHeight;
  const style = window.getComputedStyle(element);
  const marginBottom = parseInt(style.marginBottom);

  const wdWid = window.outerWidth / 7.5;
  const between = wdWid * 0.26;
  const width = wdWid * 3.32;
  const liLeft = between + width;
  console.dir(element, elementHeight);

  // 寻找当前最短列的索引
  let minColumnIndex = 0;
  for (let i = 1; i < columnHeights.length; i++) {
    if (columnHeights[i] < columnHeights[minColumnIndex]) {
      minColumnIndex = i;
    }
  }

  // 设置元素的位置（这里假设使用绝对定位）
  element.style.position = "absolute";
  element.style.top = `${columnHeights[minColumnIndex]}px`;
  element.style.left = `${minColumnIndex * liLeft}px`; // 假设每列宽度为250px

  // 更新最短列的高度
  columnHeights[minColumnIndex] += elementHeight + marginBottom;

  // // 将元素添加到DOM
  // document.querySelector('.Waterfall').appendChild(element);
  let maxHei = Math.max(...columnHeights);
  document.querySelector(".Waterfall").style.height = maxHei + "px";
};
// onMounted(() => {
  
  // nextTick(() => {
  // let wrap = document.querySelector(".Waterfall");
  // let lis = wrap.getElementsByClassName("li");
  // for (let i = 0; i < lis.length; i++) {
  //   appendElement(lis[i]);
  // }
  // });
// });
</script>
<style scoped lang="scss">
.Waterfall {
  position: relative;
  overflow: hidden;

  .li {
    float: left;
  }
  .li:nth-child(2n) {
    float: right;
  }

  // .li.type1 .pic{
  //   height: 2.25rem;

  // }
  .li .pic{
    position: relative;

    img{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      min-height: 100%;
      min-width: 100%;
    }
  }
}
.box {
}
.li {
  width: 3.32rem;
  border-radius: 0.1rem;
  background: #fff;
  margin-bottom: 0.26rem;
  overflow: hidden;
  .pic {
    position: relative;
    height: 2.34rem;
    background: no-repeat center;
    background-size: cover;
    overflow: hidden;
    .state {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 5;
      font-size: 0.2rem;
      height: 0.38rem;
      line-height: 0.38rem;
      padding: 0 0.13rem;
      color: #fff;
      background: #e62a2b;
      border-radius: 0 0 0 0.2rem;
    }
  }
  .txt {
    padding: 0.18rem 0.2rem;
    h4 {
      font-size: 0.26rem;
      color: #333;
      line-height: 0.3rem;
      margin-bottom: 0.18rem;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 设置显示的行数 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* 在最后一行添加省略号 */
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .num {
        font-size: 0.29rem;
        color: #e62a2b;
        font-family: "Gilroy-Bold", "微软雅黑";
        em {
          font-size: 0.24rem;
        }
      }
      .label {
        height: 0.3rem;
        line-height: 0.3rem;
        font-size: 0.2rem;
        color: #fb8741;
        background: #fee7d9;
        padding: 0 0.08rem;
        border-radius: 0.04rem;
      }
    }
  }

  .icon {
    height: 0.5rem;
    img {
      max-height: 0.5rem;
    }
  }
}
.jr {
  padding: 0.3rem;
  background: linear-gradient(to bottom right, #c1c8d9, #eceef3);
  &.li2 {
    background: linear-gradient(to bottom right, #b8cfe3, #e1ebf3);
    // h3{
    //     color: #434659;
    // }
    // h4{
    //     color: #434659;
    // }
    // p{
    //     color: #5b5d6a;
    // }
  }
  &.li3 {
    background: linear-gradient(to bottom right, #b8c5e6, #e1e8f6);
    // h3{
    //     color: #3f615d;
    // }
    // p{
    //     color: #3f615d;
    // }
  }
  &.li4 {
    background: linear-gradient(to bottom right, #e7d8bc, #f3efe8);
    // h3{
    //     color: #a56f47;
    // }
    // h4{
    //     color: #a56f47;
    //     margin-bottom: 0;
    // }
    // p{
    //     color: #a56f47;
    // }
  }

  .icon {
    margin-bottom: 0.2rem;
  }
  h3 {
    font-size: 0.26rem;
    color: #434659;
    line-height: 0.48rem;
    margin-bottom: 0.1rem;
    font-weight: bold;
    text-align: justify;
  }
  h4 {
    font-size: 0.22rem;
    color: #434659;
    line-height: 0.3rem;
    margin-bottom: 0.1rem;
    font-weight: bold;
    text-align: justify;
  }
  p {
    font-size: 0.22rem;
    color: #5b5d6a;
    line-height: 0.3rem;
    margin-bottom: 0.1rem;
  }
  .p {
    font-size: 0.22rem;
    color: #3c3f50;
    line-height: 0.3rem;
    margin-top: 0.26rem;
    em {
      color: #e62a2b;
    }
  }
}
</style>






